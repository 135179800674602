<template>
  <v-card max-height="600" flat max-width="375" class="mx-auto overflow-y-auto transparent">
    <v-img
      v-if="showImage"
      contain
      src="@/assets/man-woman.jpg"
      height="150px"
      dark
    >
      <v-row align="end" class="fill-height">
        <v-card-title v-if="cliente">
          <div class="display-1 text-center">
            {{ cliente.nombre }} {{ cliente.apellido }}
          </div>
        </v-card-title>
        <v-card-title v-else-if="formCliente">
          <div
            v-if="formCliente.nombre !== null && formCliente.nombre !== ''"
            class="display-1 text-center"
          >
            {{ formCliente.nombre }} {{ formCliente.apellido }}
          </div>
        </v-card-title>

        <v-card-title v-else>
          <div class="title text-center">Aquí va el nombre</div>
        </v-card-title>
      </v-row>
    </v-img>

    <v-list dense>
      <v-list-item dense v-if="!showImage">
        <v-list-item-icon>
          <v-icon color="indigo">mdi-account</v-icon>
        </v-list-item-icon>

        <v-list-item-content v-if="cliente">
          <v-list-item-title>{{
            cliente.full_name ? cliente.full_name : "N/A"
          }}</v-list-item-title>
          <v-list-item-subtitle>Nombre</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content v-if="formCliente">
          <v-list-item-title>{{
            formCliente.nombre ? formCliente.nombre : "N/A"
          }}</v-list-item-title>
          <v-list-item-subtitle>Nombre</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">mdi-phone</v-icon>
        </v-list-item-icon>

        <v-list-item-content v-if="cliente">
          <v-list-item-title>{{
            cliente.telefono ? cliente.telefono : "N/A"
          }}</v-list-item-title>
          <v-list-item-subtitle>Teléfono</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content v-if="formCliente">
          <v-list-item-title>{{
            formCliente.telefono ? formCliente.telefono : "N/A"
          }}</v-list-item-title>
          <v-list-item-subtitle>Teléfono</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="!paraEnviar">
        <v-list-item-icon>
          <v-icon color="indigo">mdi-home-variant</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Para retirar</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <template v-if="paraEnviar && formEnvio">
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo">mdi-truck-delivery</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Para enviar</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense>
          <v-list-item-icon>
            <v-icon color="indigo">mdi-map-marker</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              v-if="formEnvio.calle !== null && formEnvio.calle !== ''"
              >{{ formEnvio.calle }} {{ formEnvio.altura }} <span v-if="formEnvio.barrio">- {{ formEnvio.barrio.nombre }}</span></v-list-item-title
            >
            <v-list-item-title v-else>Calle y altura</v-list-item-title>
            <v-list-item-subtitle
              >Depto: {{ formEnvio.depto }}, Piso:
              {{ formEnvio.piso }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>

      
        <v-list-item
          class="pt-0"
          dense
          v-if="
            formEnvio.observacionEnvio !== null &&
            formEnvio.observacionEnvio !== ''
          "
        >
          <v-list-item-icon>
            <v-icon color="indigo">mdi-message-bulleted</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-list-item-title v-on="on"
                  >{{ formEnvio.observacionEnvio }}
                </v-list-item-title>
              </template>
              <span>{{ formEnvio.observacionEnvio }}</span>
            </v-tooltip>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-if="paraEnviar && cliente">
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo">mdi-truck-delivery</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Para enviar</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense>
          <v-list-item-icon>
            <v-icon color="indigo">mdi-map-marker</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              v-if="
                cliente.direccion.calle !== null &&
                cliente.direccion.calle !== ''
              "
              >{{ cliente.direccion.calle }}
              {{ cliente.direccion.altura }}</v-list-item-title
            >
            <v-list-item-title v-else>Calle y altura</v-list-item-title>
            <v-list-item-subtitle
              >Depto: {{ cliente.direccion.depto }}, Piso:
              {{ cliente.direccion.piso }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="pt-0" dense v-if="cliente.direccion.barrio">
          <v-list-item-icon>
            <v-icon color="indigo">mdi-home-group</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{
              cliente.direccion.barrio.nombre
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          class="pt-0"
          dense
          v-if="
            cliente.direccion.observacionEnvio !== null &&
            cliente.direccion.observacionEnvio !== ''
          "
        >
          <v-list-item-icon>
            <v-icon color="indigo">mdi-message-bulleted</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-list-item-title v-on="on"
                  >{{ cliente.direccion.observacionEnvio }}
                </v-list-item-title>
              </template>
              <span>{{ cliente.direccion.observacionEnvio }}</span>
            </v-tooltip>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "venta.cliente.vistaprevia",
  props: {
    formCliente: {
      type: [Object, null],
    },
    formEnvio: {
      type: [Object, null],
    },
    cliente: {
      type: [Object, null],
    },
    paraEnviar: {
      type: Boolean,
      required: true,
    },

    showImage: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
