<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="cuentasCorrientes"
           class="row-pointer transparent"
            @click:row="handleClick"
          :search="search"
          :loading="loading"
          dark
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Movimientos</v-toolbar-title>
            </v-toolbar>
          </template>
        
          <template v-slot:no-data>
            <v-alert :value="true" color="info" icon="mdi-information"
              >No hay clientes registrados.</v-alert
            >
          </template>
        </v-data-table>

        <v-dialog width="600" v-model="dialogUpdate">
          <v-card>
            <cliente-update
              v-if="dialogUpdate"
              @close-dialog="dialogUpdate = false"
              :cliente="clienteUpdate"
            ></cliente-update>
          </v-card>
        </v-dialog>

        <v-dialog width="600" v-model="dialogVistaPrevia">
          <pedido-cliente-vista-previa
            :formCliente="cliente"
            :formEnvio="envio"
          ></pedido-cliente-vista-previa>
        </v-dialog>

        <layout-dialog-delete
          close-dialog="dialogDelete= false"
          @processDelete="eliminarCliente"
          v-if="dialogDelete"
        >
          <template v-slot:title>Eliminar cliente</template>
          <template v-slot:message>
            ¿Desea eliminar el cliente '
            <i>{{ clienteParaEliminar.nombre }}</i> ' ?
          </template>
        </layout-dialog-delete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import ClienteUpdate from "@/views/cliente/ClienteUpdate";

import LayoutDialogDelete from "@/layouts/LayoutDialogDelete";
import PedidoClienteVistaPrevia from "@/components/venta/cliente/venta.cliente.vista-previa.vue";

export default {
  name: "ClienteListCtaCte",
  components: {
    ClienteUpdate,
    LayoutDialogDelete,
    PedidoClienteVistaPrevia,
  },
  props: {
    search: {
      type: String,
    },
  },
  data() {
    return {
      cuentasCorrientes: [{ id: 1, fecha: "11-01-22", comprobabte: '001-NV-0000345', debe: '$500.00', haber: '$ 0', saldo: '$10.00', vigente: true },
      { id: 2, fecha: "12-01-22", comprobabte: '001-RECIBO-0000346', debe: '0.000', haber: '$ 600.00', saldo: '$ -100', vigente: true }],
      cliente: {
        nombre: null,
        telefono: null,
        paraEnviar: false,
      },
      envio: {
        selected: "",
        calle: "",
        altura: "",
        piso: "",
        depto: "",
        observacionEnvio: "",
      },
      dialogVistaPrevia: false,

      dialogUpdate: false,
      dialogDelete: false,
      clienteParaEliminar: null,
      clienteUpdate: null,
      headers: [
        { text: "Fecha", value: "fecha" },
        { text: "Comprobante", value: "comprobabte" },
        { text: "Debe", value: "debe" },

        { text: "Haber", value: "haber" },



        { text: "Saldo", value: "saldo", sortable: false },

      ],
      loading: false,
    };
  },
  computed: {
    ...mapState("cliente", ["clientes"]),
  },
  methods: {
    ...mapActions("cliente", ["eliminar_cliente", "fetchClientes"]),
    ...mapMutations("cliente", ["setClienteSelectedUpdate"]),
    processEliminar(cliente) {
      this.dialogDelete = true;
      this.clienteParaEliminar = cliente;
    },
    eliminarCliente() {
      this.eliminar_cliente(this.clienteParaEliminar.id).then((response) => {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "red",
          text: `Se eliminó al cliente '${this.clienteParaEliminar.nombre}'`,
          icon: "mdi-bug",
          timeout: 2500,
        });
        this.dialogDelete = false;
      });
    },
    handleClick(a){
        this.$router.push({name: 'clientesCuentasCorrientesDetalle', params: { id: a.id }})
    },
    editarCliente(prop) {
      let cliente = { ...prop };
      this.clienteUpdate = cliente;
      this.dialogVistaPrevia = true;
      // let prov = { ...prop };
      // this.setClienteSelectedUpdate(prop);

      // this.$router.push({
      //   name: "actualizarCliente",

      //   params: { id: prop.id, cliente: prop },
      // });
      // this.dialogUpdate = true;
    },
  },
  created() {
    if (this.clientes.length == 0) {
      this.fetchClientes();
    }
  },
};
</script>

<style  scoped>

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
